.pagination {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  width: 100%;

  li {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    background-color: #9c27b0;
    border-radius: 50%;

    a {
      color: #ffffff;
    }
  }
}

.filters {
  div {
    margin-right: 25px;
    width: 250px;
  }
}
